import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal, NgbNavChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, Observable } from "rxjs";
import { UserService } from "src/app/_services/user/user.service";
import { AuthService } from "src/app/_modules/auth";

@Component({
	selector: "app-other-login-modal",
	templateUrl: "./other-login-modal.component.html",
	styleUrls: ["../other-login-modal/other-login-modal.component.scss"],
})
export class OtherLoginModalComponent implements OnInit {
	isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		true
	);
	isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();
	sentLoginEmail = false;
	loginForm: FormGroup;
	currentUser;
	newPasswordNeeded = false;
	newPasswordNotNeeded = false;
	loginFinish = false;
	submitted = false;
	EmailNotExist = false;
	viewPassword = false;
	viewConfirmPassword = false;
	emailEntered = false;
	passwordNotMatch = false;
	wrongPassword = false;
	user: any;
	passwordRegex =
		"^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&* -]).{12,100}$";
	passwordRegexNotMet = false;

	constructor(
		public activeModal: NgbActiveModal,
		private formBuilder: FormBuilder,
		private usersService: UserService,
		public authService: AuthService
	) {}

	ngOnInit() {
		this.isLoadingSubject.next(true);
		this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

		this.loginForm = this.formBuilder.group({
			email: [null, Validators.required],
			password: [
				null,
				[Validators.required, Validators.pattern(this.passwordRegex)],
			],
			confirm_password: [
				null,
				[Validators.required, Validators.pattern(this.passwordRegex)],
			],
		});
		this.isLoadingSubject.next(false);
	}

	toggleViewPassword() {
		this.viewPassword = !this.viewPassword;
	}

	toggleViewConfirmPassword() {
		this.viewConfirmPassword = !this.viewConfirmPassword;
	}

	async checkEmail() {
		//convert email to lower case
		let email = this.loginForm.value.email.toLowerCase();
		//check whether this email is in our system or not
		this.user = await this.usersService.checkUserExists(email);

		//if email doesn't exist
		if (this.user == null) {
			this.EmailNotExist = true;
			this.emailEntered = false;
		}
		//if user does exist
		else {
			this.EmailNotExist = false;
			//if the user has a password
			if (this.user.password) {
				this.newPasswordNotNeeded = true;
				this.newPasswordNeeded = false;
				this.emailEntered = true;
			} else {
				this.newPasswordNotNeeded = false;
				this.newPasswordNeeded = true;
				this.emailEntered = true;
			}
		}
	}

	async login() {
		//if the user needed a new password, then check they have entered one and the confirm matches
		if (this.newPasswordNeeded == true) {
			if (this.loginForm.invalid) {
				this.passwordRegexNotMet = true;
				return;
			}

			this.passwordRegexNotMet = false;
			if (
				this.loginForm.value.password == this.loginForm.value.confirm_password
			) {
				this.passwordNotMatch = false;
				//add the new password to the db (encrypted)
				await this.usersService.updatePassword(
					this.user._id,
					this.loginForm.value.password
				);

				//LOGIN
				let value = {
					email: this.loginForm.value.email.toLowerCase().trim(),
					password: this.loginForm.value.password,
					school: null,
					school_group: null,
				};

				this.activeModal.close(value);
			} else {
				this.passwordNotMatch = true;
			}
		}

		//if the user was just logging in, check the password against the user db password and if correct, let them login

		if (this.newPasswordNotNeeded == true) {
			let passwordCorrect = await this.usersService.checkPassword(
				this.user._id,
				this.loginForm.value.password
			);
			if (passwordCorrect) {
				this.wrongPassword = false;
				//LOGIN

				let value = {
					email: this.loginForm.value.email.toLowerCase().trim(),
					password: this.loginForm.value.password,
					school: null,
					school_group: null,
				};

				this.activeModal.close(value);
			} else {
				this.wrongPassword = true;
			}
		}
	}

	close() {
		this.activeModal.close(false);
	}

	get f() {
		return this.loginForm.controls;
	}

	async forgotPassword() {
		this.sentLoginEmail = true;
		await this.usersService.sendPasswordReset(
			this.loginForm.value.email.toLowerCase().trim()
		);
	}
}
