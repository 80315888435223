import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./_guards/auth.guard";

export const routes: Routes = [
  { path: "", redirectTo: "auth/login", pathMatch: "full" },
  {
    path: "password-reset",
    loadChildren: () =>
      import("./pages/password-reset/password-reset.module").then(
        (m) => m.PasswordResetModule
      ),
  },
  {
    // TODO find a way to remove
    path: "auth",
    loadChildren: () =>
      import("./pages/_auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "error",
    loadChildren: () =>
      import("./_modules/errors/errors.module").then((m) => m.ErrorsModule),
  },
  {
    path: "",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./_layout/layout.module").then((m) => m.LayoutModule),
  },
  {
    path: "school-group/assessment-cycles/:assessmentCycleID/report/print",
    loadChildren: () =>
      import(
        "./pages/assessment-cycles/school-group-assessment-cycle-report/school-group-assessment-cycle-report-print/school-group-assessment-cycle-report-print.module"
      ).then((m) => m.SchoolGroupAssessmentCycleReportPrintModule),
  },
  {
    path: "school/assessor-dashboard-print",
    loadChildren: () =>
      import(
        "./pages/assessor-school-dashboard/assessor-school-dashboard-print/assessor-school-dashboard-print.module"
      ).then((m) => m.AssessorSchoolDashboardPrintModule),
    canActivate: [AuthGuard],
  },
  { path: "**", redirectTo: "error/404" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
